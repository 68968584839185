import React from 'react';
import '../styles.css';
import logo from './logo.png'

const Header = () => {
  return (
    <>
        <div className="contact">
            <div className="email" style={{padding: 10}}>theregistrar@mikeodafeuniversity.com</div>
            <div className="list">
                <ol>
                    <li>ABOUT MOU</li>
                    <li>RESEARCH</li>
                    <li>ACADEMICS</li>
                    <li>FACULTY</li>
                    <li>ENTERPRISES</li>
                </ol>
            </div>
        </div>
        <header className="header">
            <img src={logo} alt="University Logo" className='unilogo'/>
            <div className="header-logo">
                <h1>MIKE ODAFE UNIVERSITY</h1>
                <p>VISION, VIRTUE, VOICE</p>
            </div>
            <nav className="nav-menu">
                <a href="/">ABOUT MOU</a>
                <a href="/">RESEARCH</a>
                <a href="/">ACADEMICS</a>
                <a href="/">FACULTY</a>
                <a href="/">ENTERPRISES</a>
            </nav>
        </header>
    </>
  );
};

export default Header;
