import React, { useEffect, useState } from 'react';
import '../styles.css';
import mike from "./mike.jpeg"
import fix from './fix.png'

const MainContent = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
          setWindowWidth(window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

  return (
    <div className='content'>
        <div className="main-content">
            {windowWidth <= 800 && (
                <div style={{display: "flex", justifyContent: "center"}}>
                    <img src={fix} alt="" style={{width: "40%", marginBottom: "20px"}}/>
                </div>
            )}
            <h2>{windowWidth <= 800 ? 'SITE UNDER DEVELOPMENT' : 'MIKE ODAFE UNIVERSITY'}</h2>
            <p>
                Mike Odafe University is a distinguished institution known for cultivating leadership 
                and management excellence, our comprehensive programs are designed to equip students 
                with the strategic thinking, problem-solving skills, and innovative mindset required 
                to lead in today's dynamic global landscape.
            </p>
            <p>
                Mike Odafe University offers diverse degree programs designed to foster academic 
                excellence and real-world skills. Join us to embark on a transformative educational 
                journey and achieve your career aspirations. Scholarships are a cornerstone of our 
                commitment to academic excellence and inclusivity.
            </p>
            <p>
                We offer a range of merit and need-based scholarships to support deserving students 
                in pursuing their educational goals. These scholarships not only recognize outstanding 
                achievements but also foster a diverse community where talented individuals from all 
                backgrounds can thrive.
            </p>
            <p>
                Mike Odafe University's scholarship program aims to reduce financial barriers and 
                empower students to reach their full potential.
            </p>
        </div>
        <div className="chancellor-profile">
            <img src={mike} alt="Professor Mike Odafe" />
            <p>Professor Mike Odafe<br />Chancellor MOU</p>
        </div>
    </div>
    
  );
};

export default MainContent;
