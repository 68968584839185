import React, { useState } from 'react';
import '../styles.css';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show loader

    try {
      const response = await fetch('https://mikeodafe.onrender.com/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        alert("Subscribed successfully!");  // Alert box
        setEmail('');  // Clear the input field after the user clicks 'OK' on the alert
        setMessage('Subscribed successfully!');
      } else {
        setMessage('Failed to subscribe. Please try again.');
      }
    } catch (error) {
      setMessage('An error occurred. Please try again.');
    }

    setLoading(false); // Hide loader after request is complete
  };

  return (
    <footer className="footer">
      <h3>Subscribe To Our Newsletter</h3>
      {/* <p>Subscribe now to get notified when we launch</p> */}
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Enter your email..."
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit" disabled={loading}>
          {loading ? 'Subscribing...' : 'Subscribe'}
        </button>
      </form>
      {message && <p>{message}</p>}
      <p>Contact Us: <br /> theregistrar@mikeodafeuniversity.com</p>
    </footer>
  );
};

export default Footer;
